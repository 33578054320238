import { CART_ITEM_FRAGMENT } from '../fragments/cart'

const CART_QUERY = `
  query CartsQuery($where: CartsBoolExp) {
    carts(where: $where) {
      __typename
      id
      cartItems(orderBy: { createdAt: DESC }) {
        ...CartItem
      }
      totalAmount
    }
  }
  ${CART_ITEM_FRAGMENT}
`

export default CART_QUERY
