import getEnv from "./env/getEnv";

export const GIFT_CARD_CATEGORY_ID = getEnv(
  "NEXT_PUBLIC_SHOLDI_GIFT_CARD_CATEGORY_ID",
  18,
);

const isGiftCardProduct = (product) =>
  product.typeKey === "GIFT_CARD" ||
  product?.category?.id === GIFT_CARD_CATEGORY_ID ||
  product.categoryId === GIFT_CARD_CATEGORY_ID;

export default isGiftCardProduct;
