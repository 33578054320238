export const SHIPPING_PROVIDER_FRAGMENT = `
  fragment ShippingProvider on ShippingProviders {
    id
    tracking_url
    name
    short_name
    status
    shipping_provider_fees {
      local_fee
    }
  }
`
