import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { twMerge } from "tailwind-merge";
import { useClient } from "urql";
import { uuidv7 } from "uuidv7";

import { getToast } from "@sholdi/toast";

import useTranslation from "@sholdi/hooks/useTranslation";

import useCreateCartItem from "@sholdi/graphql/operations/cart/useCreateCartItem";

import isSholdiProduct from "@sholdi/shared/lib/isSholdiProduct";
import isGiftCardProduct from "@sholdi/shared/lib/isGiftCardProduct";
import { onAddProductToCart } from "@sholdi/shared/lib/matomo/utils";

import ShoppingCart from "@sholdi/icons/ShoppingCart";

import LoadingButton from "../LoadingButton";

const AddToCartModal = dynamic(() => import("../AddToCartModal"), {
  loading: () => <p>Loading...</p>,
  ssr: false,
});

const ProductLink = dynamic(() => import("./ProductLink"));

const AddToCartButton = ({
  children,
  product,
  combination,
  className,
  disabled,
  inWidget,
  ...props
}) => {
  const [showAddToCartModal, setShowAddToCartModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { data: session } = useSession();
  const { pathname } = useRouter();
  const client = useClient();

  const addToCartMutation = useCreateCartItem(session?.user?.id);

  const sholdiProduct = isSholdiProduct(product);
  const isGiftCard = isGiftCardProduct(product);
  const isOnShopProductPage = pathname.includes("offers");
  const isProductPage = isOnShopProductPage || pathname.includes("products");

  const showOutOfStockError = useCallback(
    () => getToast("error", t("products.productOutOfStock")),
    [t],
  );

  const externalLink = combination?.settings?.url || product.externalLink;
  const shouldOpenExternalLink = externalLink && isProductPage;

  // ===========================
  // to load stock information
  // ===========================
  const loadCombination = useCallback(
    async (id) =>
      await client
        .query(
          await import(
            "@sholdi/graphql/queries/products/productCombinationAvailableStock"
          ).then((module) => module.default),
          { ids: [id] },
        )
        .toPromise(),
    [client],
  );

  const loadCombinations = useCallback(
    async (productId) =>
      await client
        .query(
          await import(
            "@sholdi/graphql/queries/products/productCombinationsByCatalogItemIdQuery"
          ).then((module) => module.default),

          { id: productId },
        )

        .toPromise(),
    [client],
  );

  const addToCart = useCallback(
    async (product, combination) => {
      setLoading(true);
      try {
        await addToCartMutation({
          variables: {
            input: {
              product,
              productCombination: combination,
              ...(!session ? { id: uuidv7() } : {}),
              quantity: 1,
            },
          },
        });
        // matomo event
        onAddProductToCart(product, combination);
      } catch (error) {
        console.error("Error adding to cart:", error);
      } finally {
        setLoading(false);
      }
    },
    [addToCartMutation, session],
  );

  const showErrorOrAddToCart = useCallback(
    async (product, combination) => {
      if (combination?.availableStock === 0) {
        showOutOfStockError();
      } else {
        await addToCart(product, combination);
      }
    },
    [showOutOfStockError, addToCart],
  );

  const onAddToCart = useCallback(async () => {
    // no product and combination, do nothing
    if (!product && !combination) return;

    // ===========================
    // SHOLDI PRODUCT CASE
    // ===========================
    if (sholdiProduct) {
      // ===========================
      // GIFT CARD PRODUCT CASE
      // ===========================
      if (isGiftCard) {
        showErrorOrAddToCart(product, combination);
        return;
      }

      // ===========================
      // LOADED COMBINATIONS FROM SHOP PRODUCTS BASED ON THE SHOLDI PRODUCT
      // IF THERE ARE MORE THAN ONE, MODAL IS SHOWN, OTHERWISE ADDED TO THE CART
      // ===========================
      const { data } = await loadCombinations(product.id);
      if (data?.productCombinations) {
        const { productCombinations } = data;
        // if there are no combinations, show error
        if (!productCombinations.length) {
          showOutOfStockError();
          return;
        }
        // if there are more than one combination, show modal
        if (productCombinations.length > 1 && !isProductPage && !inWidget) {
          setShowAddToCartModal(true);
        } else {
          // otherwise, take the only one
          const [pCombination] = productCombinations;

          showErrorOrAddToCart(pCombination.product, pCombination);
        }
      }
      // SHOLDI CASE ENDS HERE
    } else {
      // ===========================
      // PRODUCT FROM SEARCH PAGE (ELASTIC SEARCH PRODUCT)
      // ===========================
      if (product.product_id) {
        const { id, product_id: productId, ...rest } = product;
        // load combination - id is combination id
        const { data } = await loadCombination(id);
        const [combination] = data?.productCombinations || [];
        // add it to cart
        await addToCart({ id: productId, ...rest }, combination);
      } else {
        // ===========================
        // SHOP PRODUCT CASE
        // IF THERE ARE MORE COMBINATIONS, MODAL IS SHOWN, OTHERWISE ADDED TO THE CART
        // ===========================

        const count =
          product.combinationCount ?? product.productCombinations.length;
        // if there are more than one combination, show modal
        if (count > 1 && !isProductPage && !inWidget) {
          setShowAddToCartModal(true);
        } else {
          showErrorOrAddToCart(
            product,
            product.productCombination ?? product.productCombinations[0],
          );
        }
      }
    }
  }, [
    product,
    combination,
    sholdiProduct,
    isGiftCard,
    loadCombinations,
    addToCart,
    isProductPage,
    inWidget,
    showOutOfStockError,
    showErrorOrAddToCart,
    loadCombination,
  ]);

  if (shouldOpenExternalLink) {
    return <ProductLink product={product} href={externalLink} />;
  }

  return (
    <>
      <LoadingButton
        onClick={onAddToCart}
        variant="primary"
        icon={ShoppingCart}
        loading={loading}
        direction="ltr"
        iconProps={{ className: "size-4" }}
        disabled={disabled}
        className={twMerge("flex w-fit h-fit no-underline", className)}
        {...props}
      >
        {children}
      </LoadingButton>
      {showAddToCartModal && (
        <AddToCartModal
          product={product}
          open={showAddToCartModal}
          onToggle={() => setShowAddToCartModal(false)}
        />
      )}
    </>
  );
};

AddToCartButton.propTypes = {
  children: PropTypes.node,
  combination: PropTypes.shape({
    id: PropTypes.string,
    product: PropTypes.shape({}),
    availableStock: PropTypes.number,
    isExternal: PropTypes.bool,
    settings: PropTypes.shape({
      externalLink: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  product: PropTypes.shape({
    catalogItemId: PropTypes.string,
    id: PropTypes.string,
    isExternal: PropTypes.bool,
    externalLink: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    sale_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    product_id: PropTypes.string,
    shopId: PropTypes.string,
    shop: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ]),
    combinationCount: PropTypes.number,
    productCombination: PropTypes.shape({
      id: PropTypes.string,
      isExternal: PropTypes.bool,
      settings: PropTypes.shape({
        externalLink: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
    productVariations: PropTypes.arrayOf(PropTypes.shape({})),
    productCombinations: PropTypes.arrayOf(PropTypes.shape({})),
    productDescriptions: PropTypes.arrayOf(
      PropTypes.shape({ slug: PropTypes.string }),
    ),
  }),
  disabled: PropTypes.bool,
  inWidget: PropTypes.bool,
  className: PropTypes.string,
};

export default React.memo(AddToCartButton);
