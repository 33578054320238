import { useMemo, useEffect, useCallback } from "react";
import { useQuery } from "urql";

import CART_QUERY from "../../queries/cart";

import useLocalStorage from "../../useLocalStorage";
import { initialCart, context as contextValue } from "./utils";
import useSyncCart from "./useSyncCart";

const useCart = (userId, shouldSync) => {
  const { storedValue, setStoredValue } = useLocalStorage("cart", initialCart);

  const context = useMemo(() => contextValue, []);

  const [{ data: cartData, fetching: loading }] = useQuery({
    query: CART_QUERY,
    pause: !userId,
    context,
  });
  const { carts = [] } = cartData || {};

  const syncCart = useSyncCart();

  // Memoize the items calculation
  const items = useMemo(() => {
    if (!storedValue?.cartItems) {
      setStoredValue(initialCart);
      return initialCart.cartItems;
    }
    return [...storedValue.cartItems];
  }, [storedValue, setStoredValue]);

  // Use useCallback for syncCart
  const memoizedSyncCart = useCallback(
    (itemsToSync, cart) => {
      syncCart(itemsToSync, cart);
    },
    [syncCart],
  );

  // Optimize useEffect dependencies
  useEffect(() => {
    const shouldUpdateCart = shouldSync && carts[0] && items.length > 0;
    if (shouldUpdateCart) {
      setStoredValue(initialCart);
      memoizedSyncCart(items, carts[0]);
    }
  }, [carts, items, memoizedSyncCart, setStoredValue, shouldSync]);

  // Memoize the data calculation
  const data = useMemo(
    () => (carts.length ? carts[0] : storedValue),
    [carts, storedValue],
  );

  return { data, loading };
};

export default useCart;
