import { useCallback, useMemo } from "react";
import { uuidv7 } from "uuidv7";
import { useClient } from "urql";

import { generateCartItemInput, initialCart } from "../cart/utils";

import useLocalStorage from "../../useLocalStorage";
import { onCartTotalChange } from "@sholdi/shared/lib/matomo/utils";

import { getToast } from "@sholdi/toast";

import {
  CART_STATUS,
  getTotal,
  context,
  getTotalAmount,
  PRODUCT_NOT_AVAILABLE_MESSAGE,
  ADDED_TO_CART_MESSAGE,
} from "./utils";

import useCart from "./useCart";

const useCreateCartItem = (userId) => {
  const { storedValue, setStoredValue } = useLocalStorage("cart", initialCart);
  // const { data: session } = useSession();

  const client = useClient();

  const { data: cart } = useCart(userId);

  const saveToLocalStorage = useCallback(
    // eslint-disable-next-line consistent-return
    (input) => {
      let items = [...storedValue.cartItems];
      const itemInCart = items.find(
        (item) => item.productCombination.id === input.productCombination.id,
      );
      if (itemInCart) {
        if (input.productCombination.availableStock < itemInCart.quantity + 1) {
          return getToast("error", PRODUCT_NOT_AVAILABLE_MESSAGE);
        }
        items = items.map((item) => {
          if (item.productCombination.id === input.productCombination.id) {
            return {
              ...item,
              quantity: item.quantity + 1,
            };
          }
          return item;
        });
      } else {
        if (input.productCombination.availableStock < 1) {
          return getToast("error", PRODUCT_NOT_AVAILABLE_MESSAGE);
        }
        items = [
          ...storedValue.cartItems,
          {
            quantity: 1,
            product: input.product,
            productCombination: input.productCombination,
            id: uuidv7(),
          },
        ];
      }
      const { totalAmount } = getTotal(items);

      const newCart = {
        cartItems: items,
        totalAmount,
      };

      setStoredValue(newCart);

      getToast("success", ADDED_TO_CART_MESSAGE);
    },
    [setStoredValue, storedValue.cartItems],
  );

  const onCompleted = () => getToast("success", ADDED_TO_CART_MESSAGE);

  const createCartMutation = useCallback(
    async (variables) =>
      client
        .mutation(
          await import("../../mutations/cart/cartCreate").then(
            (mod) => mod.default,
          ),

          variables,
          context,
        )
        .toPromise()
        .then(onCompleted),
    [client],
  );

  const updateMutation = useCallback(
    async (variables) =>
      client
        .mutation(
          await import("../../mutations/cart/updateCartItem").then(
            (mod) => mod.default,
          ),
          variables,
          context,
        )
        .toPromise()
        .then(onCompleted),
    [client],
  );

  const mutation = useCallback(
    async (variables) =>
      client
        .mutation(
          await import("../../mutations/cart/createCartItem").then(
            (mod) => mod.default,
          ),
          variables,
          context,
        )
        .toPromise()
        .then(onCompleted),
    [client],
  );

  const isCartFromDatabase = useMemo(() => cart && "id" in cart, [cart]);

  const mutationFunction = useCallback(
    async ({ variables: { input } }) => {
      if (userId) {
        const cartMutationInput = generateCartItemInput({ cart, ...input });
        const totalAmount = getTotalAmount({
          cartItems: cart.cartItems,
          ...input,
        });
        if (isCartFromDatabase) {
          const commonInputVariables = {
            cartId: cart.id,
            totalAmount: Number(totalAmount),
          };

          if (cartMutationInput.input) {
            if (
              input.productCombination.availableStock <
              cartMutationInput.input.quantity
            ) {
              return getToast("error", PRODUCT_NOT_AVAILABLE_MESSAGE);
            }
            onCartTotalChange(commonInputVariables.totalAmount);

            return await updateMutation({
              ...cartMutationInput,
              ...commonInputVariables,
            });
          }
          if (
            input.productCombination?.availableStock <
            cartMutationInput.quantity
          ) {
            return getToast("error", PRODUCT_NOT_AVAILABLE_MESSAGE);
          }

          onCartTotalChange(commonInputVariables.totalAmount);
          return await mutation({
            input: cartMutationInput,
            ...commonInputVariables,
          });
        }

        onCartTotalChange(cartMutationInput.totalPrice);
        return await createCartMutation({
          input: {
            // TODO: remove unnecessary variables
            //  userId,
            statusKey: CART_STATUS.OPEN,
            totalAmount: cartMutationInput.totalPrice,
            //  createdAt: new Date().toISOString(),
            // updatedAt: new Date().toISOString(),
            // TODO: remove
            cartItems: {
              data: [cartMutationInput],
            },
          },
        });
      }

      return saveToLocalStorage(input);
    },
    [
      userId,
      saveToLocalStorage,
      cart,
      isCartFromDatabase,
      createCartMutation,
      mutation,
      updateMutation,
    ],
  );

  return mutationFunction;
};

export default useCreateCartItem;
