const getPrice = combination => {
  if (combination) {
    const { salePrice, price } = combination
    if (salePrice && salePrice !== '0') {
      return salePrice
    }
    return price
  }

  return null
}

export default getPrice
