import { useState, useEffect, useMemo, useRef, useCallback } from "react";

function useLocalStorage(key, initialValue) {
  const keyRef = useRef(key);

  const [storedValue, setValue] = useState(() => {
    try {
      const value = localStorage.getItem(keyRef.current);
      return value ? JSON.parse(value) : initialValue;
    } catch (err) {
      return initialValue;
    }
  });

  const setLocalItem = useCallback(() => {
    const itemValueFromStorage = localStorage.getItem(keyRef.current);
    const value = itemValueFromStorage && JSON.parse(itemValueFromStorage);
    setValue(value);
  }, []);

  const setStoredValue = useCallback((value) => {
    const parsedValue =
      typeof value === "object" ? JSON.stringify(value) : value;
    localStorage.setItem(keyRef.current, parsedValue);
    setValue(value);
    const event = new Event("storageChange");
    document.dispatchEvent(event);
  }, []);

  useEffect(() => {
    document.addEventListener("storageChange", setLocalItem);
    return () => document.removeEventListener("storageChange", setLocalItem);
  }, [setLocalItem]);

  return useMemo(
    () => ({ storedValue, setStoredValue }),
    [storedValue, setStoredValue],
  );
}

export default useLocalStorage;
