import { SHIPPING_PROVIDER_FRAGMENT } from "../fragments/shippingProviders";
import {
  FRAGMENT_PRODUCT_COMBINATIONS_MINIMAL,
  FRAGMENT_PRODUCT_DESCRIPTIONS_MINIMAL,
  FRAGMENT_PRODUCT_IMAGE_MINIMAL,
} from "./products";

export const CART_ITEM_FRAGMENT = `
  fragment CartItem on CartItems {
    __typename
    id
    quantity
    totalPrice
    shop {
      id
      name
      ownerId
    }
    productCombination {
      __typename
      ...MinimalProductCombination
    }
    product {
      __typename
      id
      typeKey
      shop {
        id
        name
      }
      productDescriptions {
        ...MinimalProductDescription
      }
      productImages {
       ...MinProductImage
      }
      productImage {
       ...MinProductImage
      }
      maxQuantity
      categoryId
      category {
        id
        parentPath
      }
    }
  }
  ${SHIPPING_PROVIDER_FRAGMENT}
  ${FRAGMENT_PRODUCT_DESCRIPTIONS_MINIMAL}
  ${FRAGMENT_PRODUCT_COMBINATIONS_MINIMAL}
  ${FRAGMENT_PRODUCT_IMAGE_MINIMAL}
`;
