import { useCallback } from "react";
import { useSession } from "next-auth/react";
import { useClient } from "urql";

import { CART_STATUS, getTotal, context } from "./utils";

const useSyncCart = () => {
  const { data: session } = useSession();

  const client = useClient();

  const syncCart = useCallback(
    async (items, cart) => {
      const cartItems = items.map(
        ({ product, productCombination, quantity }) => ({
          ...(cart ? { cartId: cart.id } : {}),
          shopId: product.shop.id,
          price: productCombination.price,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          totalPrice: productCombination.price * quantity,
          productCombinationId: productCombination.id,
          productId: product.id,
          quantity: quantity,
        }),
      );

      if (cart) {
        const mutations = [];

        const cartItemsToBeCreated = cartItems.filter(
          (item) =>
            !cart.cartItems.some(
              (cartItem) =>
                cartItem.productCombination.id === item.productCombinationId,
            ),
        );

        if (cartItemsToBeCreated.length) {
          mutations.push(
            client
              .mutation(
                await import("../../mutations/cart/cartItemBulkCreate").then(
                  (module) => module.default,
                ),
                {
                  input: cartItemsToBeCreated,
                  cartId: cart.id,
                  totalAmount:
                    getTotal(cartItems).totalAmount +
                    getTotal(cart.cartItems).totalAmount,
                },
                context,
              )
              .toPromise(),
          );
        }

        // we have already this combination in the cart, update the quantity
        const cartItemsToBeUpdated = items.filter((item) =>
          cart.cartItems.some(
            (cartItem) =>
              cartItem.productCombination.id === item.productCombination.id,
          ),
        );

        if (cartItemsToBeUpdated.length) {
          for await (const cartItem of cartItemsToBeUpdated) {
            const itemInDatabase = cart.cartItems.find(
              (cartItem) =>
                cartItem.productCombination.id ===
                cartItem.productCombination.id,
            );

            mutations.push(
              client
                .mutation(
                  await import("../../mutations/cart/updateCartItem").then(
                    (module) => module.default,
                  ),
                  {
                    id: itemInDatabase.id,
                    input: {
                      quantity: itemInDatabase.quantity + cartItem.quantity,
                    },
                    cartId: cart.id,
                    totalAmount: getTotal([...cart.cartItems, cartItem])
                      .totalAmount,
                  },
                  context,
                )
                .toPromise(),
            );
          }
        }
        if (mutations.length) {
          await Promise.all(mutations);
        }
      } else {
        await client
          .mutation(
            await import("../../mutations/cart/cartCreate").then(
              (module) => module.default,
            ),
            {
              input: {
                userId: session?.user?.id,
                statusKey: CART_STATUS.OPEN,
                totalAmount: getTotal(cartItems).totalAmount,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                cartItems: {
                  data: cartItems,
                },
              },
            },
            context,
          )
          .toPromise();
      }
    },
    [client, session?.user?.id],
  );

  return syncCart;
};

export default useSyncCart;
