import getPrice from "@sholdi/shared/helpers/getPrice";

export const getTotal = (cartItems, taxes = 0) => {
  let totalWithTaxes = 0;
  const total = cartItems.reduce(
    (totalPrice, { quantity, productCombination, price }) => {
      // eslint-disable-next-line no-param-reassign
      totalPrice += (getPrice(productCombination) ?? price) * quantity;

      return totalPrice;
    },
    0,
  );

  if (total !== 0) {
    totalWithTaxes = taxes + total;
  }

  return {
    totalAmount: total,
    totalWithTaxes,
  };
};

export const findItemInCart = (cartItems, productCombinationId) =>
  cartItems.find((item) => item.productCombination.id === productCombinationId);

export const getTotalAmount = ({ cartItems, product, productCombination }) => {
  const itemInCart = findItemInCart(cartItems, productCombination?.id);
  const item = {
    ...product,
    price: getPrice(productCombination),
    quantity: itemInCart ? itemInCart.quantity : 1,
  };
  return getTotal([...cartItems, item]).totalAmount;
};

export const generateCartItemInput = ({
  cart,
  product,
  productCombination,
}) => {
  const itemInCart = findItemInCart(cart.cartItems, productCombination?.id);

  const price = getPrice(productCombination);

  if (itemInCart) {
    return {
      id: itemInCart.id,
      input: {
        totalPrice: Number(itemInCart.totalPrice) + Number(price),
        quantity: itemInCart.quantity + 1,
      },
    };
  }

  return {
    shopId: product.shopId,
    totalPrice: price,
    price,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    productId: product.id,
    productCombinationId: productCombination?.id,
    quantity: 1,
    ...(cart.id ? { cartId: cart.id } : {}),
  };
};

export const context = { additionalTypenames: ["CartItems", "Carts"] };

export const CART_STATUS = {
  OPEN: "OPEN",
  ORDERED: "ORDERED",
};

export const initialCart = {
  cartItems: [],
  totalAmount: 0,
};

export const PRODUCT_NOT_AVAILABLE_MESSAGE = "Proizvod trenutno nije na stanju";
export const ADDED_TO_CART_MESSAGE = "Uspješno ste dodali artikl u korpu";
